@font-face {
  font-family: 'Abel-Regular';
  src: url("/src/fonts/Abel/Abel.ttf");
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 2vh 15vw;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 10000;
  align-items: center;
  transition: top 0.3s ease;
}

.logo {
  display: flex;
  height: 4vh;
  justify-content: center;
  fill: rgba(0, 0, 0, 0.85);
  padding-left: 0.5vw;
}

.text {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}

.textItem {
  display: flex;
  color: rgb(0, 0, 0, 0.7);
  font-family: 'Abel-Regular';
  text-decoration: none;
  font-size: 2.6vh;
}

.textItem:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.div3 {
  display: flex;
  height: 4vh;
  fill: transparent;
  padding-right: 0.5vw;
}

.transparent{
  background-color: transparent;
}

.transparent .textItem{
  color:rgba(255, 255, 255, 0.85);
}

.transparent .textItem:hover{
  border-bottom: 1px solid white;
}

.transparent .logo{
  fill:rgba(255, 255, 255, 0.85);
}

@media (max-width: 768px) {
  .navbar {
    padding: 2vh 0vw;
  }

  .logo {
    padding:0;
    margin: 0;
    margin-left: 2.3vw;
    width: 6vw;
  }

  .text {
    gap: 3vw;
  }

  .div3 {
    margin-right: 2.3vw;
    width: 6vw;
  }
}